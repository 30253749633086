export const employeesCardQuery = [
  'id',
  'nome',
  'sobrenome',
  'status',
  'matricula',
  'possui_acesso',
  'ultimo_login',
  'cpf',
  'valor_emprestado',
  'saldo_devedor',
]

export const employeeViewQuery = [
  'id',
  'nome',
  'bloqueado',
  'sobrenome',
  'data_nascimento',
  'sexo',
  'dependentes',
  'cpf',
  'nome_mae',
  'estado_civil',
  'margem_disponivel',
  'margem_rescisao',
  'matricula',
  'data_admissao',
  'email',
  'email_secundario',
  'descontos',
  'status',
  'limites',
  'demissao',
  'cadastro_ativo',

  'documento__tipo',
  'documento__numero',
  'documento__emissor',
  'documento__uf',

  'cargo',
  'data_admissao',
  'salario',
  'inss',
  'irrf',
  'valor_emprestado',
  'comprometimento_outros',
  'matricula',
  'proximo_corte',
  'saldo_devedor',
  'saldo_devedor_no_proximo_vencimento',

  'empresa__dia_corte',
  'pagamento__banco',
  'pagamento__agencia',
  'pagamento__agencia_dac',
  'pagamento__conta',
  'pagamento__conta_dac',
  'pagamento__tipo',

  'endereco__cep',
  'endereco__cidade',
  'endereco__uf',
  'endereco__bairro',
  'endereco__numero',
  'endereco__complemento',
  'endereco__logradouro',
  'endereco__pais',

  'referencia_nome',
  'referencia_telefone',
  'referencia_parentesco',
  'telefone_celular',
]
