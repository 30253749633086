export const lotCardQuery = [
  'mes_referencia',
  'valor_previsto',
  'valor_descontado',
  'status',
  'descontos__valor_previsto',
  'descontos__valor_descontado',
  'descontos__divergencia',
  'descontos__contrato',
  'descontos__funcionarios__nome',
  'descontos__funcionarios__sobrenome',
  'descontos__funcionarios__cpf',
]

export const lotDetailsQuery = [
  'id',
  'mes_referencia',
  'valor_previsto',
  'valor_descontado',
  'status',
  'pagamento',
  'financeira',
  'data_vencimento',
  'status',
]

export const discountsDetailsQuery = [
  'recebivel_id',
  'funcionario__nome',
  'funcionario__sobrenome',
  'funcionario__cpf',
  'funcionario__matricula',
  'divergencia',
  'valor_previsto',
  'valor_descontado',
  'mes_referencia',
]

export const lotCsvQuery = [
  'descontos__data_vencimento',
  'descontos__funcionario__cpf',
  'descontos__funcionario__matricula',
  'descontos__funcionario__nome',
  'descontos__parcela_atual',
  'descontos__recebivel__localizador',
  'descontos__recebivel__valor_parcela',
]

export const lotDashboardQuery = ['data_vencimento']
