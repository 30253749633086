import React from 'react'
import { Typography } from '@material-ui/core'

import { Container } from './styles'

interface IContentTitle {
  noMarging?: boolean
  blackColor?: boolean
  isBold?: boolean
}
const ContentTitle: React.FC<IContentTitle> = ({ children, noMarging, blackColor, isBold }) => {
  return (
    <Container noMarging={noMarging}>
      <Typography
        variant='h3'
        color={blackColor ? 'primary' : 'secondary'}
        style={{ fontWeight: isBold ? 700 : 400 }}
      >
        {children}
      </Typography>
    </Container>
  )
}

export default ContentTitle
