export const approvedContractReceivableQuery = [
  'id',
  'data_vencimento',
  'valor_parcela',
  'mes_referencia',
  'gerado_por',
  'taxa_multa',
  'cancelado',
  'ultimo_vencimento',
  'estornado_em',
  'num_parcela',
  'pagamentos__id',
  'pagamentos__data_pagamento',
  'pagamentos__total_pago',
  'pagamentos__diferenca',
  'pagamentos__estornado_em',
  'valor_presente',
  'cobranca',
  'lote',
]
