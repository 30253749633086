import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
} from '@material-ui/core'
import { AssignmentInd, People, Assignment, ExitToApp } from '@material-ui/icons'
import { useCore } from 'hooks'
import EmployeeEntity from 'app/Employee'
import DefaultEntity from 'app/Default'

import { ListItemIcon } from './styles'

interface IDialog {
  isOpen: boolean
  onClose: () => void
  name: string
  module: string
  showProfiles: boolean
}

const NavBarDialog: React.FC<IDialog> = ({ name, module, isOpen, onClose, showProfiles }) => {
  const { logout, resetEntity, entity } = useCore()
  const history = useHistory()

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby='simple-dialog-title'
      open={isOpen}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle id='simple-dialog-title'>
        <Box component='span' display='block' textAlign='center'>
          {name}
        </Box>
        <Box component='span' textAlign='center'>
          <Typography variant='subtitle1'>{module}</Typography>
        </Box>
      </DialogTitle>
      <List>
        {showProfiles && (
          <ListItem
            autoFocus
            button
            onClick={() => {
              resetEntity()
              history.push(DefaultEntity.route.PROFILES.path)
            }}
          >
            <ListItemIcon>
              <People color='secondary' />
            </ListItemIcon>
            <ListItemText primary='Trocar de perfil' />
          </ListItem>
        )}
        <ListItem
          autoFocus
          button
          onClick={() => {
            history.push(`${entity.path}${EmployeeEntity.route.MY_ACCOUNT.path}`)
            onClose()
          }}
        >
          <ListItemIcon>
            <AssignmentInd color='secondary' />
          </ListItemIcon>
          <ListItemText primary='Minha conta' />
        </ListItem>
        {entity.id === 'employee' && (
          <ListItem
            autoFocus
            button
            onClick={() => {
              history.push(`${EmployeeEntity.path}${EmployeeEntity.route.USER_TERMS.path}`)
              onClose()
            }}
          >
            <ListItemIcon>
              <Assignment color='secondary' />
            </ListItemIcon>
            <ListItemText primary='Termos de uso' />
          </ListItem>
        )}
        <ListItem
          autoFocus
          button
          onClick={() => {
            logout()
            onClose()
            history.push('')
          }}
        >
          <ListItemIcon>
            <ExitToApp color='error' />
          </ListItemIcon>
          <Box color='error.main'>
            <ListItemText primary='Sair' />
          </Box>
        </ListItem>
      </List>
    </Dialog>
  )
}

export default NavBarDialog
