import { useCallback, useEffect, useRef, useState } from 'react'
import { simulationFields } from 'queries'
import { IEmployee, IFactor, IQueryParams, ISimulationConfig } from 'interfaces'
import { get, orderBy, sumBy } from 'lodash'
import { calcSimulate, verifyLimits, isModality as CheckModality } from 'helpers'

import useApi from './useApi'
import useCore from './useCore'

interface IHooksSimulation {
  queryParams?: IQueryParams
  pathSimulation: string
  isRenegociate: boolean
  isParcel: boolean
  pathEmployeeInformation?: string
}

interface GetMinMaxParams {
  factor?: IFactor
  parcel?: boolean
}

export function useSimulationConfig({
  pathSimulation,
  queryParams,
  isRenegociate,
  isParcel = false,
  pathEmployeeInformation,
}: IHooksSimulation) {
  // Objeto de configuração de dados necessários para simulação
  const [simulationConfig, setSimulationConfig] = useState<ISimulationConfig>()
  // Campos principais para cálculo
  const [selectedFactor, setSelectedFactor] = useState<IFactor>()
  const [valueRequest, setValueRequest] = useState<number>(0)
  const [totalValue, setTotalValue] = useState<number>(0)

  const [selectedFirstFactor, setSelectedFirstFactor] = useState<number>(0)
  const [valueRenegociateContracts, setValueRenegociateContracts] = useState<number>(0)
  // Habilita seguro
  const [enableInsurer, setEnableInsurer] = useState<boolean>(false)
  // Valores referente ao cálculo com seguro
  const [valueWithoutInsurer, setValueWithoutInsurer] = useState<number>(0)
  const [valueInsurer, setValueInsurer] = useState<number>(0)
  const [valueParcelInsurer, setValueParcelInsurer] = useState<number>(0)
  const [isRefreshInformations, setIsRefreshInformations] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [employee, setEmployee] = useState<IEmployee>()
  const { apiRequest } = useApi()
  const { entity } = useCore()
  const queryParamsRef = useRef<IQueryParams>(queryParams || {})

  const isModality = CheckModality(entity)
  const isCDC = isModality === 'CDC'
  const isCDV = isModality === 'CDCVEICULO'
  const isCorban = isModality === 'CORBAN'

  const isCDCandRenegociate = isCDC && isRenegociate
  const isCDVandRenegotiate = isCDV && isRenegociate

  const handleChange = (value: number) => {
    setValueRequest(value)
  }

  const handleChangeParcela = (value: IFactor) => {
    setSelectedFactor(value)
  }

  const handleBlur = (value: number) => {
    setValueRequest(value)
    setIsRefreshInformations(false)
  }

  const handleFocus = () => {
    setIsRefreshInformations(true)
  }

  // Pegar limite minimo
  const getMinValue = ({ factor, parcel }: GetMinMaxParams) => {
    const currentMode = parcel !== undefined ? parcel : isParcel
    if (selectedFactor) {
      const currentFactor = factor || selectedFactor

      if (simulationConfig && simulationConfig.limites) {
        if (currentMode) {
          if (currentFactor.parcela_minima >= simulationConfig.limites.financeira_valor_minimo) {
            return currentFactor.parcela_minima
          }
          if (currentFactor.parcela_minima > 0) {
            return simulationConfig.limites.financeira_valor_minimo / currentFactor.fator
          }
          return 0
        }

        if (currentFactor.valor_minimo >= simulationConfig.limites.financeira_valor_minimo) {
          return currentFactor.valor_minimo
        }
        if (currentFactor.valor_minimo > 0) {
          return simulationConfig.limites.financeira_valor_minimo
        }
      }
      return currentFactor.valor_minimo
    }
    return 0
  }

  // Função para saber o valor máximo da financeira
  const isFinancialMaxValue = (factor?: IFactor) => {
    if (selectedFactor) {
      const currentFactor = factor || selectedFactor
      if (simulationConfig) {
        const maxValueSimulation = simulationConfig.limites?.financiamento
          ? simulationConfig.limites.financiamento
          : simulationConfig.limites.financeira_valor_maximo
        return maxValueSimulation < currentFactor.valor_maximo
      }
    }
    return false
  }

  // Limite máximo
  const getMaxValues = ({ factor, parcel }: GetMinMaxParams) => {
    const currentMode = parcel !== undefined ? parcel : isParcel
    if (selectedFactor) {
      const currentFactor = factor || selectedFactor
      const maxValue = currentMode ? currentFactor.parcela_maxima : currentFactor.valor_maximo
      if (isFinancialMaxValue(factor) && simulationConfig) {
        const maxValueSimulation = simulationConfig.limites?.financiamento
          ? simulationConfig.limites.financiamento
          : simulationConfig.limites.financeira_valor_maximo
        return currentMode ? maxValueSimulation / currentFactor.fator : maxValueSimulation
      }
      return maxValue
    }
    return 0
  }

  const initialValues = () => {
    if (selectedFactor) {
      if (isParcel) {
        return isRenegociate ? valueRenegociateContracts / selectedFactor.fator : getMaxValues({})
      }
      return isRenegociate ? valueRenegociateContracts : getMaxValues({})
    }
    return 0
  }

  const resetFields = () => {
    if (simulationConfig) {
      const lastElement = simulationConfig.fatores[simulationConfig.fatores.length - 1]

      setValueRequest(initialValues())
      setSelectedFactor(lastElement)
    }
  }

  // Bloqueio do input para solicitação e renegociação de crédito
  const isBlockedInput = () => {
    if (isCDCandRenegociate || isCDVandRenegotiate) {
      return true
    }

    if (simulationConfig && selectedFactor && simulationConfig.limites) {
      const minValueInvalid =
        selectedFactor.valor_minimo < simulationConfig.limites.financeira_valor_minimo
      const maxValueInvalid = getMaxValues({}) < selectedFactor.valor_minimo
      return minValueInvalid || maxValueInvalid
    }

    return false
  }

  const configureInsurer = (factor: IFactor, value?: number) => {
    const valueWithoutInsurer: number = value || factor.parcela_maxima

    calculateInsurer(factor, valueWithoutInsurer)
    setValueWithoutInsurer(valueWithoutInsurer)
    setSelectedFactor(factor)
  }

  const calculateInsurer = (factor: IFactor, value?: number) => {
    const valueToCalculate: number = value || factor.parcela_maxima

    if (factor.seguro_porcentagem) {
      setValueInsurer(factor.seguro / 100)
      setValueParcelInsurer(valueToCalculate * (factor.seguro / 100))
    } else {
      setValueInsurer(factor.seguro)
    }
  }

  const getSimulationConfig = useCallback(async () => {
    const response = await apiRequest({
      path: pathSimulation,
      method: 'GET',
      queryParams: {
        ...queryParamsRef.current,
        fields: simulationFields,
      },
    })

    if (response && !response?.data) {
      const orderedFactors = orderBy(response.fatores, 'prazo')
      const contractValues = response.contratos ? sumBy(response.contratos, 'saldo_devedor') : 0
      const isContainsInsurer = response?.seguradora
      if (isContainsInsurer) setEnableInsurer(true)
      if (contractValues) setValueRenegociateContracts(contractValues)
      setSimulationConfig({
        ...response,
        fatores: orderedFactors,
        seguradora: isContainsInsurer,
      })
      const factor = get(orderedFactors, `[${orderedFactors ? orderedFactors?.length - 1 : 0}]`)

      if (factor) {
        configureInsurer(factor)
        setSelectedFirstFactor(factor?.prazo)
      } else {
        setSelectedFactor({} as IFactor)
      }
      return
    }
    setError(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRequest, setSimulationConfig, setSelectedFactor, pathSimulation, isRenegociate])

  const getEmployees = useCallback(async () => {
    if (pathEmployeeInformation) {
      const response = await apiRequest({
        path: pathEmployeeInformation,
        method: 'GET',
        queryParams: {
          fields: 'nome,sobrenome,id,cpf,telefone_celular',
          search: queryParams?.funcionario_cpf?.replace(/[^\d]+/g, ''),
        },
      })

      if (response && response.results?.length) {
        setEmployee(response.results[0])
        return
      }
      setEmployee({} as IEmployee)
      return
    }
  }, [apiRequest, queryParams, pathEmployeeInformation])

  useEffect(() => {
    getSimulationConfig()
  }, [getSimulationConfig])

  useEffect(() => {
    if (isCorban) getEmployees()
  }, [getEmployees, isCorban])

  useEffect(() => {
    if (selectedFactor) {
      if (valueRequest === 0) {
        handleChange(initialValues())
      } else {
        const maxValue = getMaxValues({})
        const minValue = getMinValue({})
        handleChange(verifyLimits(valueRequest, maxValue, minValue))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFactor])

  useEffect(() => {
    handleChange(initialValues())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParcel])

  useEffect(() => {
    if (selectedFactor) {
      const maxValue = getMaxValues({ parcel: false })
      const minValue = getMinValue({ parcel: false })
      const maxValueParcel = getMaxValues({ parcel: true })
      const minValueParcel = getMinValue({ parcel: true })
      const newValue = calcSimulate({
        values: valueRequest,
        factor: selectedFactor,
        maxValue,
        minValue,
        maxValueParcel,
        minValueParcel,
        isParcel: isParcel,
      })
      setTotalValue(newValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueRequest, selectedFactor, isParcel])

  return {
    simulationConfig,
    selectedFactor,
    selectedFirstFactor,
    valueRenegociateContracts,
    valueWithoutInsurer,
    valueInsurer,
    valueParcelInsurer,
    error,
    queryParamsRef,
    valueRequest,
    totalValue,
    isRefreshInformations,
    enableInsurer,
    getMinValue,
    getMaxValues,
    initialValues,
    handleChange,
    handleChangeParcela,
    resetFields,
    isBlockedInput,
    setSelectedFactor,
    handleBlur,
    handleFocus,
    employee,
  }
}

export default useSimulationConfig
