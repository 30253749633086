export enum CORBAN_SUBMODULES {
  CONFIG = 'config',
  CEP = 'cep',
  CP = 'cp',
}

export enum SALESCHANNEL_SUBMODULES {
  CP = 'CP',
  CDC = 'CDC',
  CDV = 'CDCVEICULO',
  PJ = 'PJ',
}
// Adicionar outros submodulos caso tenha a necessidade
export type TSubmodules = CORBAN_SUBMODULES | SALESCHANNEL_SUBMODULES
