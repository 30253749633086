import { useContext } from 'react'
import { ApiContext } from 'components/Provider/ApiProvider'
import PATHS from 'constants/paths'

export function useApi() {
  const apiContext = useContext(ApiContext)
  const SUBMODULE_PATHS: { [key: string]: string } = PATHS[apiContext.subModule || ''] || {}

  return {
    ...apiContext,
    SUBMODULE_PATHS,
  }
}

export default useApi
