import styled from 'styled-components'
import { Width, ZIndex } from 'constants/css'

interface IContent {
  isLoading?: boolean
}

export const SidePanel = styled.div`
  position: fixed;
  height: 0;
  padding-left: ${Width.SIDEBAR_COLLAPSED};
  top: 0;
  height: 100%;
  min-height: 100vh;
  width: ${Width.SIDEBAR};
  overflow: hidden;
  transition: 0.2s all cubic-bezier(0.22, 0.61, 0.36, 1);
  box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.1);
  z-index: ${ZIndex.SIDEPANEL};
  background: white;
`

export const Content = styled.div<IContent>`
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ isLoading }) =>
    !isLoading &&
    `
    height: 100%;
  `}
`
