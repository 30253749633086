export const pendingAverbationQuery = [
  'id',
  'averbar_ate',
  'funcionario__nome',
  'funcionario__sobrenome',
  'funcionario__cpf',
  'funcionario__valor_emprestado',
  'funcionario__comprometimento_outros',
  'funcionario__salario',
  'funcionario__inss',
  'funcionario__irrf',
  'funcionario__matricula',
  'funcionario__margem_disponivel',
  'funcionario__descontos',
  'assinatura_info',
  'ccb_original_html',
  'ccb_original_pdf',
  'cliente_info_no_contrato__salario_liquido',
  'prazo',
  'valor_recebivel',
  'valor_parcela',
  'data_solicitacao',
  'status_averbacao',
  'valor_financiado',
  'origem',
]

export const deniedAverbationQuery = [
  'id',
  'funcionario__nome',
  'funcionario__sobrenome',
  'funcionario__cpf',
  'funcionario__valor_emprestado',
  'funcionario__comprometimento_outros',
  'funcionario__salario',
  'funcionario__matricula',
  'funcionario__margem_disponivel',
  'funcionario__salario_margem',
  'funcionario__descontos',
  'prazo',
  'valor_recebivel',
  'status_averbacao',
  'valor_financiado',
  'origem',
]

export const approvedAverbationQuery = [
  'id',
  'funcionario__nome',
  'funcionario__cpf',
  'funcionario__sobrenome',
  'funcionario__matricula',
  'funcionario__telefone_celular',
  'prazo',
  'valor_recebivel',
  'valor_financiado',
  'status_averbacao',
  'valor_financiado',
  'data_solicitacao',
  'status',
  'origem',
  'migrado_em',
]

export const viewApprovedQuery = [
  'id',
  'funcionario__nome',
  'funcionario__sobrenome',
  'data_primeiro_vencimento',
  'data_ultimo_vencimento',
  'ultimo_vencimento',
  'prazo',
  'valor_recebivel',
  'status_averbacao',
  'valor_financiado',
  'valor_seguro',
  'data_solicitacao',
  'valor_iof',
  'valor_seguro',
  'status',
  'taxa_iof_complementar',
  'taxa_iof',
  'taxa_cet_mes',
  'valor_tarifas',
  'taxa_multa',
  'valor_total_devido',
  'valor_liberado',
  'origem',
  'taxa_permanencia',
  'ccb_original_html',
  'ccb_original_pdf',
  'proposta',
  'valor_liberado',
  'metadados',
  'metadados__info_cancelamento',
  'alienacao',
  'entrada',
  'migrado_em',
  'agente_certificado',
]

export const employeeViewApprovedQuery = [
  'id',
  'localizador',
  'funcionario__nome',
  'funcionario__sobrenome',
  'financeira',
  'data_primeiro_vencimento',
  'data_ultimo_vencimento',
  'ultimo_vencimento',
  'prazo',
  'valor_recebivel',
  'status_averbacao',
  'valor_financiado',
  'valor_solicitado',
  'valor_seguro',
  'data_solicitacao',
  'valor_iof',
  'valor_seguro',
  'status',
  'taxa_iof',
  'taxa_contrato',
  'taxa_iof_complementar',
  'taxa_cet_mes',
  'taxa_cet_ano',
  'cet_anual',
  'taxa_contrato_ano',
  'valor_tarifas',
  'taxa_multa',
  'valor_total_devido',
  'valor_liberado',
  'origem',
  'taxa_permanencia',
  'parcelas_info',
  'saldo_devedor',
  'alienacao',
  'entrada',
  'ccb_original_pdf',
  'ccb_original_html',
  'valor_a_liquidar',
  'migrado_em',
]

export const dashboardQuery = ['id', 'data_efetivacao', 'ultimo_vencimento']

export const cpContractsQuery = [
  'id',
  'localizador',
  'cliente_info_no_contrato__nome',
  'cliente_info_no_contrato__sobrenome',
  'cliente_info_no_contrato__cpf',
  'prazo',
  'proposta',
  'valor_recebivel',
  'status_averbacao',
  'valor_financiado',
  'data_solicitacao',
  'status',
  'origem',
  'taxa_cet_mes',
  'taxa_contrato',
  'valor_liberado',
  'metadados',
  'metadados__info_cancelamento',
  'modalidade',
]

export const cpSignConctract = [
  'id',
  'localizador',
  'ccb_original_pdf',
  'ccb_original_html',
  'valor_seguro',
  'status',
  'modalidade',
]

export const corbanCEPContracts = [
  'status',
  'convenio',
  'empresa__nome_fantasia',
  'origem',
  'data_pagamento',
  'cliente_info_no_contrato__nome',
  'cliente_info_no_contrato__sobrenome',
  'cliente_info_no_contrato__cpf',
  'cliente_info_no_contrato__email',
  'cliente_info_no_contrato__pagamento',
  'funcionario__nome',
  'funcionario__sobrenome',
  'funcionario__cpf',
  'data_solicitacao',
  'corban',
  'cpf_operador',
  'nome_operador',
  'localizador',
  'canal_atendimento',
  'data_primeiro_vencimento',
  'data_ultimo_vencimento',
  'status_averbacao',
  'telefone_celular',
  'financeira_valor_a_pagar',
  'prazo',
  'valor_financiado',
  'valor_parcela',
  'email',
  'data_averbacao',
  'id',
  'autorizador_msg',
  'migrado_em',
]

export const corbanCEPContractsCSV = [
  'status',
  'empresa__nome_fantasia',
  'localizador',
  'data_solicitacao',
  'status_averbacao',
  'data_averbacao',
  'data_pagamento',
  'pagamento__banco',
  'pagamento__agencia',
  'pagamento__agencia_dac',
  'pagamento__conta',
  'pagamento__conta_dac',
  'canal_atendimento',
  'corban',
  'nome_operador',
  'cpf_operador',
  'origem',
  'nome_funcionario',
  'funcionario__cpf',
  'funcionario__telefone_celular',
  'funcionario__telefone_celular_verificado',
  'funcionario__email',
  'funcionario__email_verificado',
  'data_primeiro_vencimento',
  'data_ultimo_vencimento',
  'valor_financiado',
  'financeira_valor_a_pagar',
  'prazo',
  'valor_parcela',
]

export const corbanCEPPendingTed = [
  'canal',
  'empresa__nome_fantasia',
  'empresa__localizador',
  'origem',
  'convenio',
  'funcionario__nome',
  'funcionario__sobrenome',
  'funcionario__cpf',
  'data_primeiro_vencimento',
  'data_ultimo_vencimento',
  'ultimo_vencimento',
  'status_averbacao',
  'status',
  'localizador',
  'data_pagamento',
  'data_solicitacao',
  'telefone_celular',
  'cliente_info_no_contrato__pagamento',
  'financeira_valor_a_pagar',
  'email',
  'ccb_original_html',
  'ccb_original_pdf',
  'migrado_em',
]

export const corbanCEPPendingSingQuery = [
  'status',
  'convenio',
  'empresa__nome_fantasia',
  'origem',
  'cliente_info_no_contrato__nome',
  'cliente_info_no_contrato__sobrenome',
  'cliente_info_no_contrato__cpf',
  'cliente_info_no_contrato__email',
  'cliente_info_no_contrato__pagamento',
  'funcionario__nome',
  'funcionario__sobrenome',
  'funcionario__cpf',
  'data_solicitacao',
  'data_primeiro_vencimento',
  'data_ultimo_vencimento',
  'telefone_celular',
  'financeira_valor_a_pagar',
  'prazo',
  'valor_financiado',
  'valor_parcela',
  'email',
  'id',
  'localizador',
  'autorizador_msg',
  'valor_solicitado',
  'valor_recebivel',
  'nome',
  'migrado_em',
]

export const corbanCEPPendingSing = [
  'empresa__nome_fantasia',
  'nome_funcionario',
  'status',
  'data_solicitacao',
  'localizador',
  'funcionario__telefone_celular',
  'pagamento__banco',
  'valor_financiado',
  'prazo',
  'valor_recebivel',
  'data_ultimo_vencimento',
]

export const pjContractsQuery = [
  'id',
  'localizador',
  'financeira',
  'cliente',
  'cliente_info_no_contrato__nome',
  'cliente_info_no_contrato__sobrenome',
  'cliente_info_no_contrato__cpf',
  'prazo',
  'proposta',
  'valor_recebivel',
  'status_averbacao',
  'valor_financiado',
  'data_solicitacao',
  'status',
  'origem',
  'taxa_cet_mes',
  'taxa_contrato',
  'valor_liberado',
  'metadados',
  'metadados__info_cancelamento',
  'parcelas_info',
  'saldo_devedor',
]

export const lateContractsQuery = ['id', 'localizador', 'parcelas_info', 'financeira', 'proposta']
