import LazyLoading from 'components/LazyLoading'
import {
  HomeOutlined,
  SupervisorAccount,
  Receipt,
  AccountBalance,
  Person,
  CompareArrows,
} from '@material-ui/icons'
import { hasPermission } from 'permissions'
import { routeCodes, subRouteCodes } from 'constants/routes'
import { IRoute } from 'interfaces'

const Dashboard = LazyLoading(() => import('./pages/Dashboard'))
const DashboardCompanySelect = LazyLoading(() => import('./pages/Dashboard/CompanySelect'))
const DashboardDetails = LazyLoading(() => import('./pages/Dashboard/Details'))
const Employee = LazyLoading(() => import('./pages/Employee'))
const EmployeeList = LazyLoading(() => import('./pages/Employee/List'))
const EmployeeCompanySelect = LazyLoading(() => import('./pages/Employee/CompanySelect'))
const EmployeeView = LazyLoading(() => import('./pages/Employee/View'))
const EmployeeForm = LazyLoading(() => import('./pages/Employee/Form'))
const EmployeeDemission = LazyLoading(() => import('./pages/Employee/Demission'))
const EmployeeDemissionList = LazyLoading(() => import('./pages/Employee/Demission/List'))
const EmployeeDemissionForm = LazyLoading(() => import('./pages/Employee/Demission/Form'))
const EmployeeImport = LazyLoading(() => import('./pages/Employee/Import'))
const EmployeeImportCsv = LazyLoading(() => import('./pages/Employee/ImportCSV'))
//const EmployeeDemissionLote = LazyLoading(() => import('./pages/Employee/ImportDemissionLote'))
const EmployeeDemissionLoteV2 = LazyLoading(() => import('./pages/Employee/ImportDemissionLoteV2'))
const LGPDEmployees = LazyLoading(() => import('./pages/Employee/LGPDEmployees'))
const LGPDEmployeeView = LazyLoading(() => import('./pages/Employee/LGPDEmployees/View'))
const TransferEmployees = LazyLoading(() => import('./pages/Employee/Transfer'))
const TransferEmployeesView = LazyLoading(() => import('./pages/Employee/Transfer/View'))
const Contract = LazyLoading(() => import('./pages/Contract'))
const ContractCompanySelect = LazyLoading(() => import('./pages/Contract/CompanySelect'))
const ContractPending = LazyLoading(() => import('./pages/Contract/Pending'))
const ContractDenieds = LazyLoading(() => import('./pages/Contract/Denied'))
const Contracts = LazyLoading(() => import('./pages/Contract/Contracts'))
const ContractList = LazyLoading(() => import('./pages/Contract/Contracts/List'))
const ContractView = LazyLoading(() => import('./pages/Contract/Contracts/View'))
const PendingPortability = LazyLoading(() => import('./pages/Contract/PendingPortability'))
const PendingPortabilityList = LazyLoading(() => import('./pages/Contract/PendingPortability/List'))
const PendingPortabilityView = LazyLoading(() => import('./pages/Contract/PendingPortability/View'))
const Covenant = LazyLoading(() => import('./pages/Covenant'))
const CovenantCompanySelect = LazyLoading(() => import('./pages/Covenant/CompanySelect'))
const CovenantList = LazyLoading(() => import('./pages/Covenant/List'))
const CovenantView = LazyLoading(() => import('./pages/Covenant/View'))
const User = LazyLoading(() => import('./pages/User'))
const UserCompanySelect = LazyLoading(() => import('./pages/User/CompanySelect'))
const UserList = LazyLoading(() => import('./pages/User/List'))
const UserView = LazyLoading(() => import('./pages/User/View'))
const UserForm = LazyLoading(() => import('./pages/User/Form'))
const Repass = LazyLoading(() => import('./pages/Repass'))
const RepassCompanySelect = LazyLoading(() => import('./pages/Repass/CompanySelect'))
const RepassView = LazyLoading(() => import('./pages/Repass/View'))
const RepassDiscount = LazyLoading(() => import('./pages/Repass/Discount'))
const RepassDiscountInfo = LazyLoading(() => import('./pages/Repass/Discount/Info'))
const RepassDiscountTransfer = LazyLoading(() => import('./pages/Repass/Discount/Transfer'))
const MyAccount = LazyLoading(() => import('./pages/MyAccount'))
const MyAccountList = LazyLoading(() => import('./pages/MyAccount/List'))
const MyAccountChangeMail = LazyLoading(() => import('./pages/MyAccount/ChangeMail'))
const MyAccountChangePassword = LazyLoading(() => import('./pages/MyAccount/ChangePassword'))
const MyAccountChangePhone = LazyLoading(() => import('./pages/MyAccount/ChangePhone'))
const MyAccountToken = LazyLoading(() => import('./pages/MyAccount/Token'))
const Redirect404 = LazyLoading(() => import('components/Redirect404'))

export const DefaultRoutes: { [key: string]: IRoute } = {
  DEFAULT: {
    path: routeCodes.NULL_ROUTE,
    name: 'Página não encontrada',
    component: Redirect404,
    hideInSidePanel: true,
  },
  DASHBOARD: {
    path: routeCodes.COMPANY.DASHBOARD,
    name: 'Página Inicial',
    component: Dashboard,
    icon: HomeOutlined,
    defaultRoute: true,
    routes: {
      COMPANY_SELECT: {
        path: '/',
        name: 'Selecionar empresa',
        component: DashboardCompanySelect,
        hideInSidePanel: true,
      },
      DEFAULT: {
        path: '/details',
        name: 'Página inicial',
        component: DashboardDetails,
        hideInSidePanel: true,
      },
    },
  },
  EMPLOYEE: {
    path: routeCodes.COMPANY.EMPLOYEE,
    name: 'Funcionários',
    permissions: [hasPermission('CEP_FUNCIONARIOS_GET')],
    component: Employee,
    icon: SupervisorAccount,
    routes: {
      COMPANY_SELECT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Selecionar empresa',
        component: EmployeeCompanySelect,
        hideInSidePanel: true,
      },
      LIST: {
        path: subRouteCodes.LIST,
        name: 'Listar funcionários',
        component: EmployeeList,
      },
      VIEW: {
        path: `${subRouteCodes.LIST}/:id`,
        name: 'Visualizar Funcionário',
        component: EmployeeView,
        hideInSidePanel: true,
      },
      NEW: {
        path: subRouteCodes.NEW,
        name: 'Novo Funcionário',
        component: EmployeeForm,
        hideInSidePanel: true,
      },
      EDIT: {
        path: `${subRouteCodes.EDIT}/:id`,
        name: 'Editar Funcionário',
        component: EmployeeForm,
        hideInSidePanel: true,
      },
      DEMISSION: {
        path: subRouteCodes.COMPANY.EMPLOYEE.DEMISSION,
        name: 'Demissão',
        component: EmployeeDemission,
        routes: {
          DEFAULT: {
            path: routeCodes.NULL_ROUTE,
            name: 'Demissão',
            component: EmployeeDemissionList,
          },
          FORM: {
            path: '/:id',
            name: 'Demissão',
            component: EmployeeDemissionForm,
          },
        },
      },
      /*
      DEMISSION_LOTE: {
        path: subRouteCodes.COMPANY.EMPLOYEE.DEMISSION_LOTE,
        name: 'Demissão em lote V1',
        component: EmployeeDemissionLote,
      },
      */
      DEMISSION_LOTE_V2: {
        path: subRouteCodes.COMPANY.EMPLOYEE.DEMISSION_LOTE_V2,
        name: 'Demissão em lote',
        component: EmployeeDemissionLoteV2,
      },
      IMPORT: {
        path: subRouteCodes.COMPANY.EMPLOYEE.IMPORT,
        name: 'Importar por XLSX',
        component: EmployeeImport,
      },
      IMPORT_CSV: {
        path: subRouteCodes.COMPANY.EMPLOYEE.IMPORT_CSV,
        name: 'Importar por CSV',
        component: EmployeeImportCsv,
      },
      LGPD_EMPLOYEES: {
        path: subRouteCodes.COMPANY.EMPLOYEE.LGPD_EMPLOYEES,
        name: 'Novos funcionários - LGPD',
        component: LGPDEmployees,
      },
      LGPD_EMPLOYEE: {
        path: `${subRouteCodes.COMPANY.EMPLOYEE.LGPD_EMPLOYEES}/:id`,
        name: 'Editar funcionário - LGPD',
        component: LGPDEmployeeView,
        hideInSidePanel: true,
      },
      TRANSFER_EMPLOYEES: {
        path: subRouteCodes.COMPANY.EMPLOYEE.TRANSFER,
        name: 'Transferência',
        component: TransferEmployees,
      },
      TRANSFER_EMPLOYEES_VIEW: {
        path: `${subRouteCodes.COMPANY.EMPLOYEE.TRANSFER}/:id`,
        name: 'Transferência de Funcionário',
        component: TransferEmployeesView,
        hideInSidePanel: true,
      },
    },
  },
  CONTRACT: {
    path: routeCodes.COMPANY.CONTRACT,
    name: 'Contratos',
    component: Contract,
    permissions: [hasPermission('CEP_CONTRATOS_GET')],
    icon: Receipt,
    routes: {
      COMPANY_SELECT: {
        path: '/',
        name: 'Selecionar empresa',
        component: ContractCompanySelect,
        hideInSidePanel: true,
      },
      PENDENT: {
        path: subRouteCodes.COMPANY.CONTRACT.PENDING,
        name: 'Pendentes de Averbação',
        component: ContractPending,
      },
      PENDING_PORTABILITY: {
        path: subRouteCodes.COMPANY.CONTRACT.PENDING_PORTABILITY,
        name: 'Portabilidades pendentes de averbação',
        component: PendingPortability,
        permissions: [hasPermission('CEP_PORTABILIDADES_AVERBAR')],
        routes: {
          DEFAULT: {
            path: routeCodes.NULL_ROUTE,
            name: 'Portabilidades pendentes de averbação',
            component: PendingPortabilityList,
          },
          VIEW: {
            path: '/:id',
            name: 'Visualizar portabilidade',
            component: PendingPortabilityView,
          },
        },
      },
      DENIED: {
        path: subRouteCodes.COMPANY.CONTRACT.DENIED,
        name: 'Averbação Negada',
        component: ContractDenieds,
      },
      EXPIRATED: {
        path: subRouteCodes.COMPANY.CONTRACT.EXPIRATED,
        name: 'Averbação Expirada',
        component: Contracts,
        routes: {
          DEFAULT: {
            path: routeCodes.NULL_ROUTE,
            name: 'Expirados',
            component: ContractList,
          },
          VIEW: {
            path: `${subRouteCodes.VIEW}/:id`,
            name: 'Visualizar Contrato',
            component: ContractView,
          },
        },
      },
      APPROVED: {
        path: subRouteCodes.COMPANY.CONTRACT.APPROVED,
        name: 'Aprovados',
        component: Contracts,
        routes: {
          DEFAULT: {
            path: routeCodes.NULL_ROUTE,
            name: 'Aprovados',
            component: ContractList,
          },
          VIEW: {
            path: `${subRouteCodes.VIEW}/:id`,
            name: 'Visualizar Contrato',
            component: ContractView,
          },
        },
      },
      CANCELATED: {
        path: subRouteCodes.COMPANY.CONTRACT.CANCELATED,
        name: 'Cancelados',
        component: Contracts,
        routes: {
          DEFAULT: {
            path: routeCodes.NULL_ROUTE,
            name: 'Cancelados',
            component: ContractList,
          },
          VIEW: {
            path: `${subRouteCodes.VIEW}/:id`,
            name: 'Visualizar Contrato',
            component: ContractView,
          },
        },
      },
      FINISHED: {
        path: subRouteCodes.COMPANY.CONTRACT.FINISHED,
        name: 'Encerrados',
        component: Contracts,
        routes: {
          DEFAULT: {
            path: routeCodes.NULL_ROUTE,
            name: 'Encerrados',
            component: ContractList,
          },
          VIEW: {
            path: `${subRouteCodes.VIEW}/:id`,
            name: 'Visualizar Contrato',
            component: ContractView,
          },
        },
      },
    },
  },
  USER: {
    path: routeCodes.COMPANY.USER,
    name: 'Usuários',
    permissions: [hasPermission('CRED_USUARIOS_LIST')],
    component: User,
    icon: Person,
    routes: {
      COMPANY_SELECT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Selecionar empresa',
        component: UserCompanySelect,
        hideInSidePanel: true,
      },
      VIEW: {
        path: '/:id',
        name: 'Visualizar Usuário',
        component: UserView,
      },
      DEFAULT: {
        path: subRouteCodes.LIST,
        name: 'Usuários',
        component: UserList,
      },
      NEW: {
        path: subRouteCodes.NEW,
        name: 'Novo usuário',
        component: UserForm,
      },
      EDIT: {
        path: `${subRouteCodes.EDIT}/:id`,
        name: 'Editar usuário',
        component: UserForm,
      },
    },
  },
  REPASS: {
    path: routeCodes.COMPANY.REPASS,
    name: 'Repasse',
    permissions: [hasPermission('CEP_LOTES_GET')],
    component: Repass,
    icon: CompareArrows,
    routes: {
      COMPANY_SELECT: {
        path: '',
        name: 'Selecionar empresa',
        component: RepassCompanySelect,
        hideInSidePanel: true,
      },
      VIEW: {
        path: subRouteCodes.COMPANY.REPASS.DETAILS,
        name: 'Detalhamento',
        component: RepassView,
      },
      DISCOUNT: {
        path: subRouteCodes.COMPANY.REPASS.DISCOUNT.DEFAULT,
        name: 'Desconto em folha',
        component: RepassDiscount,
        routes: {
          INFO: {
            path: routeCodes.NULL_ROUTE,
            name: 'Informações',
            component: RepassDiscountInfo,
          },
          TRANSFER: {
            path: subRouteCodes.COMPANY.REPASS.DISCOUNT.TRANSFER,
            name: 'Transferência',
            component: RepassDiscountTransfer,
          },
        },
      },
    },
  },
  COVENANT: {
    path: routeCodes.COMPANY.COVENANT,
    name: 'Convênios',
    permissions: [hasPermission('CEP_CONVENIOS_GET')],
    component: Covenant,
    icon: AccountBalance,
    routes: {
      COMPANY_SELECT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Selecionar empresas',
        component: CovenantCompanySelect,
        hideInSidePanel: true,
      },
      VIEW: {
        path: '/:id',
        name: 'Visualizar Convênio',
        component: CovenantView,
      },
      DEFAULT: {
        path: subRouteCodes.LIST,
        name: 'Convênios',
        component: CovenantList,
      },
    },
  },
  MY_ACCOUNT: {
    path: routeCodes.COMPANY.MY_ACCOUNT,
    name: 'Minha Conta',
    component: MyAccount,
    hideInSidePanel: true,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Minha Conta',
        component: MyAccountList,
      },
      CHANGE_MAIL: {
        path: subRouteCodes.COMPANY.MY_ACCOUNT.CHANGE_MAIL,
        name: 'Alterar Email',
        component: MyAccountChangeMail,
      },
      CHANGE_PASSWORD: {
        path: subRouteCodes.COMPANY.MY_ACCOUNT.CHANGE_PASSWORD,
        name: 'Alterar Senha',
        component: MyAccountChangePassword,
      },
      CHANGE_TELEPHONE: {
        path: subRouteCodes.COMPANY.MY_ACCOUNT.CHANGE_PHONE,
        name: 'Alterar Telefone',
        component: MyAccountChangePhone,
      },
      TOKEN: {
        path: subRouteCodes.COMPANY.MY_ACCOUNT.TOKEN,
        name: 'Confirmar código',
        component: MyAccountToken,
      },
    },
  },
}

export default DefaultRoutes
