import { Moment } from 'moment'

export const cpfNormalizer = (value: string) => {
  if (value) {
    const onlyNums = value.replace(/[^\d]/g, '')

    if (onlyNums.length <= 3) {
      return onlyNums
    }
    if (onlyNums.length <= 6) {
      return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`
    }
    if (onlyNums.length <= 9) {
      return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}`
    }

    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}-${onlyNums.slice(9, 11)}`
  }
  return value
}

export const chassiNormalizer = (value: string) => {
  if (value) {
    const onlyValidValues = value.replace(/[^0-9a-zA-Z]+/g, '')

    if (onlyValidValues.length <= 3) {
      return onlyValidValues
    }
    if (onlyValidValues.length <= 9) {
      return `${onlyValidValues.slice(0, 3)} ${onlyValidValues.slice(3, 9)}`
    }
    if (onlyValidValues.length <= 11) {
      return `${onlyValidValues.slice(0, 3)} ${onlyValidValues.slice(3, 9)} ${onlyValidValues.slice(
        9,
        11
      )}`
    }

    return `${onlyValidValues.slice(0, 3)} ${onlyValidValues.slice(3, 9)} ${onlyValidValues.slice(
      9,
      11
    )} ${onlyValidValues.slice(11, 17)}`
  }
  return value
}

export const onlyNumbers = (value: string) => {
  if (value) {
    const onlyNums = value.replace(/[^\d]/g, '')
    return onlyNums
  }
  return value
}

export const cnpjNormalizer = (value: string) => {
  if (value) {
    const onlyNums = value.replace(/[^\d]/g, '')

    if (onlyNums.length <= 2) {
      return onlyNums
    }
    if (onlyNums.length <= 5) {
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}`
    }
    if (onlyNums.length <= 8) {
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}`
    }
    if (onlyNums.length <= 12) {
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
        5,
        8
      )}/${onlyNums.slice(8, 12)}`
    }

    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`
  }
  return value
}

export const cepNormalizer = (value: string) => {
  if (value) {
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 5) {
      return onlyNums
    }
    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`
  }
  return value
}

export const phoneNormalizer = (value: string) => {
  if (value) {
    const onlyNums = value.replace(/[^\d]/g, '')

    if (onlyNums.length <= 2) {
      return `${onlyNums}`
    }
    if (onlyNums.length <= 7) {
      return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}`
    }
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)} ${onlyNums.slice(7, 11)}`
  }
  return value
}

export const dateParse = (date: Moment): string => {
  return date ? date.format('YYYY-MM-DD') : ''
}

export const dateNormalizer = (value: string, previousValue: string) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (!previousValue || previousValue.length < 8 || value.length > 9) {
    if (onlyNums.length <= 2) {
      return `${onlyNums}`
    }
    if (onlyNums.length <= 4) {
      return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 5)}`
    }

    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4, 8)}`
  }

  return value
}

export const maxLength = (max: number) => (value: string) => {
  if (value) {
    const onlyNums = value.slice(0, max)
    return onlyNums
  }
  return value
}

export const percentParse = (value: string) => {
  if (!value) {
    return 0
  }
  const val = Number(value)
  if (val <= 0) {
    return 0
  } else if (val > 100) {
    return 100
  }
  return val
}
