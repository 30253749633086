import styled from 'styled-components'
import { breakpoint } from 'helpers'

export const Box = styled.div`
  background: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;

  & > div {
    position: relative;
    padding: 2rem;
  }

  @media (min-width: ${breakpoint('sm')}) {
    & > div {
      min-width: 550px;
      min-height: 250px;
    }
  }

  @media (max-width: ${breakpoint('sm')}) {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    min-width: unset;

    & > div {
      position: relative;
      padding: 2rem 0.5rem 0.5rem 0.5rem;
    }
  }
`

export const OnidataLink = styled.a`
  text-decoration: underline;
`
