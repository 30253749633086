import { IEmployee } from './employee'
import { IContract } from './contract'
import { ICompany } from './company'
import { IFinancial } from './financial'
import IPayment from './payment'
import { DOCUMENT_STATUS } from 'interfaces'
import { ICovenant } from './covenant'
import { IDisposal } from './disposal'
import { IContractPJInfo } from './contractInfo'

export enum DocumentsKeys {
  IDENTITY_FRONT = 'identidade_frente',
  IDENTITY_REVERSE = 'identidade_verso',
  INCOME = 'renda',
  RESIDENCE = 'residencia',
}

export interface IProposal {
  migrado_em: string
  funcionario: IEmployee
  empresa: ICompany
  contrato: IContract
  id: string
  localizador: string
  financeira: IFinancial
  status: string
  data_primeiro_vencimento: string
  data_ultimo_vencimento: string
  origem: string
  documentacao_comprovacao: {
    [DocumentsKeys.IDENTITY_FRONT]?: IDocuments
    [DocumentsKeys.IDENTITY_REVERSE]?: IDocuments
    [DocumentsKeys.INCOME]?: IDocuments
    [DocumentsKeys.RESIDENCE]?: IDocuments
  }
  ultimo_vencimento: string
  data_solicitacao: string
  valor_solicitado: number
  valor_recebivel: number
  prazo: number
  valor_liberado: number
  valor_tarifas: number
  valor_iof: number
  valor_financiado: number
  valor_seguro: number
  valor_juros: number
  taxa_cet_mes: number
  modalidade: string
  pagamento: IPayment
  nome: string
  sobrenome: string
  cpf: string
  telefone_celular: string
  convenio: ICovenant
  status_averbacao: string
  autorizador_msg: string
  alienacao: IDisposal
  sac_telefone: string
  sac_email: string
}

export interface IDocuments {
  alterado_em: string
  data_aprovacao: string
  documento: string
  id: number
  motivo_negado: string
  status_documento: DOCUMENT_STATUS
  tipo: string
}

export interface IProposalPJ {
  cliente_info_no_contrato: IContractPJInfo
  cpf_operador: string
  data_solicitacao: string
  id: string
  localizador: string
  modalidade: string
  nome_administrador: string
  nome_fantasia: string
  nome_operador: string
  prazo: number
  status: string
  valor_recebivel: number
  valor_solicitado: number
  cnpj: string
}
